<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>過濾器</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>產品種類</label>
          <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :value="productTypeFilter"
            :options="productTypeOptions" label="name" class="w-100" :reduce="(val) => val.id"
            @input="(val) => $emit('update:productTypeFilter', val)">
          </v-select>
        </b-col>

        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>產品品牌</label>
          <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :value="productBrandFilter"
            :options="productBrandOptions" label="name" class="w-100" :reduce="(val) => val.id"
            @input="(val) => $emit('update:productBrandFilter', val)" />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>狀態</label>
          <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :value="statusFilter" :options="statusOptions"
            label="name" class="w-100" :reduce="(val) => val.value"
            @input="(val) => $emit('update:statusFilter', val)" />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label></label>
          <div>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary" @click="reset"
              style="height: 40px">
              重置
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormDatepicker,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

export default {
  data() {
    return {
      statusOptions: [
        { value: 1, name: '啟用' },
        { value: 0, name: '禁用' },
        { value: -1, name: '全部' },
        { value: -2, name: '已刪除' }
      ]

    }
  },
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BFormDatepicker,
    BButton,
  },
  methods: {
    reset() {
      this.$emit("update:statusFilter", null);
      this.$emit("update:productTypeFilter", null);
      this.$emit("update:productBrandFilter", null);
    }
  },
  props: {
    productTypeFilter: {
      type: [Number, null],
      default: null,
    },
    statusFilter: {
      type: [Number, null],
      default: null,
    },
    productBrandFilter: {
      type: [Number, null],
      default: null,
    },
    productTypeOptions: {
      type: Array,
      required: true,
    },
    productBrandOptions: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>