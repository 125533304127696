import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'

export default function useProductsList() {

    
    //User toast
    const toast = useToast()

    const refProductListTable = ref(null)

    const tableColumns = [
        {key: 'model', label:'產品型號', sortable: true},
        {key: 'product_type.name', label:'產品類型'},
        {key: 'product_brand.name', label:'產品品牌'},
        {key: 'name', label:'產品名稱', sortable: true},
        {key: 'description', label: '描述'},
        {key: 'bar_code', label: '條碼'},
        {key: 'price', label: '單價'},
        {key: 'cost', label: '成本'},
        {key: 'is_active', label: '狀態'},
        {key: 'actions', label: '操作'},
    ]

    //Filter
    const productBrandFilter = ref(null)
    const productTypeFilter = ref(null)
    const statusFilter = ref(-1)

    const perPage = ref(10)
    const totalProducts = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)

    const dataMeta = computed(() => {
        const localItemsCount = refProductListTable.value ? refProductListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalProducts.value,
        }
    })

    const refetchData = () => {
        refProductListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, productBrandFilter, productTypeFilter, statusFilter], () => {
        refetchData()
    })

    const fetchProducts = (ctx, callback) => {
        store.dispatch('product/fetchProducts',{
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            product_brand_id: productBrandFilter.value,
            product_type_id: productTypeFilter.value,
            is_active: statusFilter.value
        })
        .then(response =>{
            const {products, total} = response.data
            callback(products)
            totalProducts.value = total
        
        })
        .catch((error) => {
            console.log(error)
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error fetching product list',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                }
            })
        })
    }

    const productBrandOptions = ref([])
    const productTypeOptions = ref([]) 

    onMounted(() => {
        store.dispatch('app/fetchOptionList', {product_type: true, product_brand: true})
        .then(response => {
            productBrandOptions.value = response.data.product_brands
            productTypeOptions.value = response.data.product_types
        }).catch(error => {
            console.log(error)
        })
    })

    return {
        fetchProducts,
        tableColumns,
        perPage,
        currentPage,
        totalProducts,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refProductListTable,
        ability,
        refetchData,
        productBrandFilter,
        productTypeFilter,
        statusFilter,
        productBrandOptions,
        productTypeOptions
    }

}