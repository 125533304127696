import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProduct(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/products/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchProducts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/products", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addProduct(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/products", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteProduct(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/products", { params: data })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    restoreProduct(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .patch(`/products/${id}/restore`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    }
  },
};
