<template>
    <div>
      <product-add-new
        :is-add-new-product-sidebar-active.sync="isAddNewProductSidebarActive"
        :id="id"
        :data="productData"
        :product-brand-options="productBrandOptions"
        :product-type-options="productTypeOptions"
        @refetch-data="refetchData"
        v-if="ability.can('update', 'products')"
      >
      </product-add-new>

      <product-list-filter
        :status-filter.sync="statusFilter"
        :product-brand-filter.sync="productBrandFilter"
        :product-brand-options="productBrandOptions"
        :product-type-filter.sync="productTypeFilter"
        :product-type-options="productTypeOptions"
      />
      <b-card no-body class="mb-0">
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Show</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entries</label>
            </b-col>
  
            <!-- Search -->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="搜尋..."
                />
                <b-button variant="primary" @click="handleCreate"  v-if="ability.can('create', 'products')">
                  <span class="text-nowrap">新增產品</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
  
        <b-table
          ref="refProductListTable"
          class="position-relative"
          :items="fetchProducts"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
        <template #cell(is_active)="data">
          <feather-icon 
          v-if="data.item.is_active && data.item.deleted_at == null"
          icon="CheckIcon"
          size="18"
          class="mr-50 text-success"
          />
           <span v-if="data.item.deleted_at" class="text-danger">已刪除</span>

      </template>

          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-if="ability.can('update', 'products')"
                @click="handleEdit(data.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">修改產品資料</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="ability.can('delete', 'products') && data.item.deleted_at == null"
                @click="confirmDelete(data.item.id)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">刪除</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="ability.can('delete', 'products') && data.item.deleted_at"
                @click="confirmRestore(data.item.id)"
              >
                <feather-icon icon="RotateCcwIcon" />
                <span class="align-middle ml-50">恢復</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
            >
              <span class="text-muted"
                >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalProducts"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </template>
  
  <script>
  import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
  } from "bootstrap-vue";
  import vSelect from "vue-select";
  import store from "@/store";
  import { ref, onUnmounted, onMounted } from "@vue/composition-api";
  import { avatarText } from "@core/utils/filter";
  import productStoreModule from "../productStoreModule";
  import useProductsList from "./useProductsList";
  import ProductAddNew from "./ProductListAddNew.vue";
  import Ripple from "vue-ripple-directive";
  import ProductListFilter from "./ProductListFilter.vue";
  
  export default {
    directives: {
      Ripple,
    },
    components: {
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      vSelect,
      ProductAddNew,
      ProductListFilter,
    },
    methods: {
      handleUpload(item) {
        this.openModal = !this.openModal;
        if (item.client) {
          this.client_code = item.client.client_code;
        }
        this.product_id = item.product_id;
        this.$bvModal.show("modal-file-manager");
      },
      openStageModal(item) {
        this.productData = { ...item };
        this.$bvModal.show("modal-stage");
      },
      openApplicationModal(item) {
        this.productData = { ...item };
        this.$bvModal.show("modal-application");
      },
      getNumberFormat(amount) {
        var formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "HKD",
          minimumFractionDigits: 0,
        });
        return formatter.format(amount);
      },
      handleCreate() {
        this.id = 0;
        this.resetProductData();
        this.isAddNewProductSidebarActive = true;
      },
      handleEdit(item) {
        this.resetProductData();
        this.productData = {...item}
        this.productData.id = item.id
        this.id = item.id
        this.isAddNewProductSidebarActive = true;
      },
      resetProductData() {
        this.productData = {
          id: 0,
          is_active: 1,
          name: '',
          model: '',
          product_brand_id: null,
          product_type_id: null,
          description: '',
          price: 0,
          cost: 0,
          bar_code: ''
        };
      },
      confirmDelete(id) {
        this.$swal({
          title: "確認刪除?",
          text: "您的操作是最終的，您將無法檢索該產品。",
          showCancelButton: true,
          confirmButtonText: "刪除",
          cancelButtonText: '取消',
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.$store
              .dispatch("product/deleteProduct", { id: id })
              .then((response) => {
                this.$swal({
                  text: response.data.message,
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
                this.refetchData();
                this.refetchStatistic();
              })
              .catch((error) => {
                this.$swal({
                  text: JSON.stringify(error.response.data.message),
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
              });
          }
        });
      },
      confirmRestore(id) {
        this.$swal({
          title: "確認刪恢復?",
          showCancelButton: true,
          confirmButtonText: "恢復",
          cancelButtonText: '取消',
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.$store
              .dispatch("product/restoreProduct", id)
              .then((response) => {
                this.$swal({
                  text: response.data.message,
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
                this.refetchData();
                this.refetchStatistic();
              })
              .catch((error) => {
                this.$swal({
                  text: JSON.stringify(error.response.data.message),
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
              });
          }
        });
      },
    },
    data() {
      return {
        id: 0,
        productData: {},
        client_code:'',
        product_id: '',
        openModal: false,
      };
    },
    setup() {
      const PRODUCT_STORE_MODULE_NAME = "product";

      if (!store.hasModule(PRODUCT_STORE_MODULE_NAME))
        store.registerModule(PRODUCT_STORE_MODULE_NAME, productStoreModule);
  
      onUnmounted(() => {
        if (store.hasModule(PRODUCT_STORE_MODULE_NAME))
          store.unregisterModule(PRODUCT_STORE_MODULE_NAME);
      });
  
      const isAddNewProductSidebarActive = ref(false);
     
      const {
       
        fetchProducts,
        tableColumns,
        perPage,
        currentPage,
        totalProducts,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refProductListTable,
        refetchData,
        ability,
        productBrandFilter,
        productTypeFilter,
        statusFilter,
        productBrandOptions,
        productTypeOptions
      } = useProductsList();
  
      return {
        //Sidebar
        isAddNewProductSidebarActive,
        fetchProducts,
        tableColumns,
        perPage,
        currentPage,
        totalProducts,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refProductListTable,
        refetchData,
        avatarText,
        ability,
        productBrandFilter,
        productTypeFilter,
        statusFilter,
        productBrandOptions,
        productTypeOptions
      };
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  .badge {
    width:100%;
  }
  </style>