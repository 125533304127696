<template>
  <b-sidebar id="add-new-product-sidebar" :visible="isAddNewProductSidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-product-sidebar-active', val)">
    <template #default="{ hide }">
      <div class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        ">
        <h5 class="mb-0">修改產品資料</h5>
        <div>
          <feather-icon class="ml-1 cursor-pointer" icon="SaveIcon" size="18" style="color: green" @click="onSubmit" />
          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
      </div>

      <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
        <!-- Body -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <b-form-group class="" label="產品品牌" label-for="product_brand_id">
              <v-select v-model="productData.product_brand_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="productBrandOptions" label="name" :reduce="(option) => option.id" :clearable="false">
              </v-select>
            </b-form-group>
            <b-form-group class="" label="產品類型" label-for="product_type_id">
              <v-select v-model="productData.product_type_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="productTypeOptions" label="name" :reduce="(option) => option.id" :clearable="false">
              </v-select>
            </b-form-group>

            <validation-provider #default="validationContext" key="model" name="產品型號" rules="required">
              <b-form-group class="required" label="產品型號" label-for="model">
                <b-form-input v-model="productData.model" class="" placeholder="產品型號"
                  :state="getValidationState(validationContext)" />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider #default="validationContext" key="name" name="產品名稱" rules="required">
              <b-form-group class="required" label="產品名稱" label-for="name">
                <b-form-input v-model="productData.name" class="" placeholder="產品名稱"
                  :state="getValidationState(validationContext)" />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-form-group label="產品描述" label-for="description">
              <b-form-textarea v-model="productData.description" placeholder="產品描述" />
            </b-form-group>
            <b-form-group class="" label="條碼" label-for="bar_code">
              <b-form-input v-model="productData.bar_code" placeholder="條碼" />
            </b-form-group>
            <b-form-group class="" label="單價" label-for="price">
              <b-form-input v-model="productData.price" placeholder="單價" type="number" />
            </b-form-group>
            <b-form-group class="" label="成本" label-for="cost">
              <b-form-input v-model="productData.cost" placeholder="成本" type="number" />
            </b-form-group>

            <b-col cols="12" md="12">
              <b-form-group label="狀態" label-for="is_active" label-cols-md="3">
                <b-form-checkbox :checked="productData.is_active" name="is_active" switch inline
                  v-model="productData.is_active" value="1">
                  {{ productData.is_active ? "啟用" : "禁用" }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>


            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
                儲存
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
                @click="hide">
                取消
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-sidebar>
</template>
<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BOverlay,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BCol
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { useToast } from "vue-toastification/composition";
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormTextarea,
    BOverlay,
    BFormDatepicker,
    quillEditor,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BCol
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewProductSidebarActive",
    event: "update:is-add-new-product-sidebar-active",
  },
  props: {
    productBrandOptions: {
      type: Array,
      required: true,
    },
    isAddNewProductSidebarActive: {
      type: Boolean,
      required: true,
    },
    productTypeOptions: {
      type: Array,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      field: ''
    };
  },
  methods: {
    hide() {
      //this.isAddNewProductSidebarActive = false;
      this.$emit('update:is-add-new-product-sidebar-active', false);
    },
    onSubmit() {
      this.$swal({
        title: "確定儲存？",
        text: "",
        showCancelButton: true,
        confirmButtonText: "儲存",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          this.$store
            .dispatch("product/addProduct", this.productData)
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.loading = false;
              this.hide();
              this.$emit("refetch-data");
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  setup(props, { emit }) {
    const blankProductData = {
      id: 0,
      is_active: 1,
      name: '',
      model: '',
      product_brand_id: null,
      product_type_id: null,
      description: '',
      price: 0,
      cost: 0,
      bar_code: ''
    };
    const loading = ref(false);

    const productData = ref(JSON.parse(JSON.stringify(blankProductData)));

    const resetproductData = () => {
      productData.value = JSON.parse(JSON.stringify(blankProductData));
    };

    watch(
      () => [props.id, props.isAddNewProductSidebarActive],
      () => {
        if (props.id > 0 && props.isAddNewProductSidebarActive) {
          productData.value = { ...props.data };
        } else {
        }
      }
    );

    const editorOption = {
      modules: {
        toolbar: "#quill-toolbar-product",
      },
      placeholder: "remark",
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetproductData);

    return {
      productData,
      loading,
      refFormObserver,
      getValidationState,
      resetForm,
      editorOption,
    };
  },
};
</script>

<style>
.ql-editor {
  height: 300px;
}
</style>
